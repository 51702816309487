import {Injectable} from '@angular/core';
import {NgModule} from '@angular/core';

import {Type} from '@angular/core';

export class AdItem {
    constructor(public component: Type<any>) {
    }
}

@NgModule({})
@Injectable()
export class ModelClasses {

    classes = {
        // 'listitem': new AdItem(ListItem),
        // 'songlist': new AdItem(SongList),
    };


    constructor() {


    }


    //depricated!
    public getClass(modelPrefix) {
        // console.log(modelPrefix);
        var pref = modelPrefix.toLowerCase();
        // console.log(pref);
        // console.log(this.classes[pref]);
        return this.classes[pref] ? this.classes[pref].component : null;
    }

    public getModelClass(modelPrefix) {
        // console.log(modelPrefix);
        var pref = modelPrefix.toLowerCase();
        // console.log(pref);
        // console.log(this.classes[pref]);
        return this.classes[pref] ? this.classes[pref].component : null;
    }
}
