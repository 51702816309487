import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ModelProvider} from "../framework/providers/modelProvider/ModelProvider";
import {Contact} from "../model/contact";

@Component({
    selector: 'app-shaps',
    templateUrl: './shaps.component.html',
    styleUrls: ['./shaps.component.css']
})
export class ShapsComponent implements OnInit {

    apps = {
        "semicom": {
            'name': 'סמיקום',
            'apkName': 'semicom.apk',
            'contactName': 'מלכה',
            'imageFolderName': 'semicom'
        },
        "zahavi-trading": {
            'name': 'זהבי סחר',
            'apkName': 'zahavi-trading.apk',
            'contactName': 'לך',
            'imageFolderName': 'zahavi-trading'
        },
        "gabriel": {
            'name': 'גבריאל גורמט',
            'apkName': 'gabriel.apk',
            'contactName': 'גבי',
            'imageFolderName': 'gabriel'
        },
        "momedia": {
            'name': 'מומדיה',
            'apkName': 'momedia.apk',
            'contactName': 'אלון',
            'imageFolderName': 'momedia'
        }
    };
    // appName = "zahavi-trading";
    appName = "gabriel";

    appData;

    constructor(private route: ActivatedRoute, public modelProvider: ModelProvider) {
        // super(props);
        let name = this.route.snapshot.queryParamMap.get("kid");
        this.route.queryParamMap.subscribe(queryParams => {
            this.appName = queryParams['params']['kid'];
            this.appData = this.apps[this.appName];
        });
    }

    ngOnInit() {
        // this.appName = 'semicom';
        // this.appData = this.apps[this.appName];
    }


    send() {

    }

}
