import {Component, Input} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Contact} from "./model/contact";
import {ModelProvider} from "./framework/providers/modelProvider/ModelProvider";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'onebtnsiteang';


    constructor() {

        // this.appName = 'semicom';
        // this.appData = this.apps[this.appName];
    }

    // private route: ActivatedRoute) {
    //     // super(props);
    //     let name = this.route.snapshot.queryParamMap.get("kid");
    //     this.route.queryParamMap.subscribe(queryParams => {
    //         this.appName = queryParams['params']['kid'];
    //         this.appData = this.apps[this.appName];
    //     });
    //
    // }


}


@Component({
    selector: 'shapsPreffesional',
    templateUrl: './shaps/sections/preffesional.html'
})
export class ShapsPreffesionalComponent {
    @Input()
    appData;
}

@Component({
    selector: 'shapsNewapp',
    templateUrl: './shaps/sections/newapp.html'
})
export class ShapsNewappComponent {
    @Input()
    appData;
}

@Component({
    selector: 'shapsTopslider',
    templateUrl: './shaps/sections/topslider.html'
})
export class ShapsTopsliderComponent {
    @Input()
    appData;

    slidersData = [{
        'subTitle': 'הגיע הזמן להתקדם',
        'title': 'קטלוג ומערכת הזמנות - באפליקציה',
        'imageName': 'section-bg-123.jpg',
    },

        {
            'title': 'אותם הלקוחות, אותם המוצרים, אותן ההזמנות, עכשיו בלחיצת כפתור',
            'subTitle': 'שאפס, אפליקציית מכירות',
            'imageName': 'section-bg-4.jpg',
        },
        {
            'title': 'באיזו שעה אתם מפסיקים לתת שירות?',
            'subTitle': 'שאפס זמינה 24/7',
            'imageName': 'section-bg-3.jpg',
        },
        {
            'title': 'רכשו מוצר שאינו במלאי?',
            'subTitle': 'עם שאפס, זה לא יקרה.',
            'imageName': 'section-bg-7.jpg',
        },
        {
            'title': 'מוצר חדש על המדף?',
            'subTitle': 'שאפס מפיצה אותך וחושפת את המוצרים שלך',
            'imageName': 'section-bg-6.jpg',
        },

        {
            'title': 'עסוקים בלהעתיק הזמנות?',
            'subTitle': 'שאפס תכניס את ההזמנה ישירות למערכת הניהול שלכם',
            'imageName': 'section-bg-7.jpg',
        },
        {
            'title': 'יוצאים במבצע חדש?',
            'subTitle': 'שאפס עושה לך אפסייל',
            'imageName': 'section-bg-2.jpg',
        },
        {
            'title': 'חדש בחנויות אנדרויד ואייפון - האפליקציה שלך',
            'subTitle': 'קליק ללקוח, קליק לסוכן, קליק למחסנאי',
            'imageName': 'section-bg-4.jpg',
        },

        {
            'subTitle': 'הפלטפורמה מוכנה, ואתה?',
            'title': 'שאפס',
            'imageName': 'section-bg-3.jpg',
        },
    ]
}

@Component({
    selector: 'appTopslider',
    templateUrl: './shaps/sections/topslider.html'
})
export class AppTopsliderComponent {
    @Input()
    appData;


    slidersData = [
        {
            'title': 'One Button',
            'subTitle': 'מקשרים בין אנשים לאנשים, בין אנשים למכונות, בין מכונות למכונות',
            'imageName': 'section-bg-1.jpg',
        }, {
            'title': 'אפליקציית מכירות בלחיצת כפתור',
            'subTitle': 'קל להטמיע, קל להשתמש',
            'imageName': 'section-bg-2.jpg',
        },
        {
            'title': 'אותם הלקוחות, אותם המוצרים, אותן ההזמנות, עכשיו בלחיצת כפתור',
            'subTitle': 'הכל נכנס לרוטציה',
            'imageName': 'section-bg-4.jpg',
        },
        {
            'title': 'לקוח מבצע הזמנה לפי מחירון אישי, סוכן מאשר ומעביר לביצוע',
            'subTitle': 'אוטומציה לתהליך ההזמנה',
            'imageName': 'section-bg-6.jpg',
        },
        {
            'title': 'ממשקים למערכת ERP פנימית - סאפ, פריוריטי, חשבשבת ועוד',
            'subTitle': 'משיכה ודחיפת נתונים למערכת הניהול של העסק',
            'imageName': 'section-bg-1.jpg',
        },
        {
            'subTitle': 'ללקוחות שלך, שרוצים להישאר קרובים',
            'title': 'אפליקציה שעושה מוניטין - בתפירה לפי מאפייני הלקוח',
            'imageName': 'section-bg-3.jpg',
        },
        {
            'title': 'חדש בחנויות אנדרויד ואייפון - האפליקציה שלך',
            'subTitle': 'שכפול הזמנה בקליק',
            'imageName': 'section-bg-4.jpg',
        },
        {
            'title': 'חשיפת לקוחות לקטלוג המוצרים המלא',
            'subTitle': 'מגלים את קצה המדף',
            'imageName': 'section-bg-5.jpg',
        },
        {
            'title': 'מבצעי חיסול מלאי וקידום מכירות',
            'subTitle': 'אחד פלוס אחד, באנדלים ועוד',
            'imageName': 'section-bg-6.jpg',
        },

        {
            'title': 'שיפור משמעותי מול פתרון של אתר מותאם',
            'subTitle': 'הזמנה זאת חוויה, חוויה נעימה עם טעם של עוד',
            'imageName': 'section-bg-1.jpg',
        },
        {
            'subTitle': 'מעולה ללקוחות חוזרים או להזמנות קבועות',
            'title': 'אפליקציית הזמנות - להזמנות בקליק',
            'imageName': 'section-bg-2.jpg',
        },
        {
            'subTitle': 'כל אחד בזמן שלו',
            'title': 'לקוחות מחפשים אותך? ממתינים על הקו כדי להשיג אותך? חבל שאין לך שאפס',
            'imageName': 'section-bg-3.jpg',
        },
        {
            'subTitle': 'הפלטפורמה מוכנה, ואתה?',
            'title': 'וואן באטן, מתמחים בהטמעת מערכות אפליקטיביות',
            'imageName': 'section-bg-3.jpg',
        },
    ]
}

@Component({
    selector: 'shapsServices',
    templateUrl: './shaps/sections/services.html'
})
export class ShapsServicesComponent {
    @Input()
    appData;
}

//

@Component({
    selector: 'shapsPortfolio',
    templateUrl: './shaps/sections/portfolio.html'
})
export class ShapsPortfolioComponent {
    @Input()
    appData;

    tab = 1;
}

@Component({
    selector: 'shapsFeaturs',
    templateUrl: './shaps/sections/features.html'
})
export class ShapsFeatursComponent {
    @Input()
    appData;
}

@Component({
    selector: 'shapsTestimonials',
    templateUrl: './shaps/sections/testimonials.html'
})
export class ShapsTestimonialsComponent {
    @Input()
    appData;
}

@Component({
    selector: 'shapsBlog',
    templateUrl: './shaps/sections/blog.html'
})
export class ShapsBlogComponent {
    @Input()
    appData;
}

@Component({
    selector: 'shapsNewsletter',
    templateUrl: './shaps/sections/newsletter.html'
})
export class ShapsNewsletterComponent {
    @Input()
    appData;
}

@Component({
    selector: 'shapsContact',
    templateUrl: './shaps/sections/contact.html'
})
export class ShapsContactComponent {
    @Input()
    appData;

    contactModel: Contact = new Contact({});

    constructor(public modelProvider: ModelProvider) {

    }


    send() {
        this.modelProvider.create(this.contactModel, {}, 'arto.shaps.co.il/oneadminarto').then((data) => {

        });
    }
}
