import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfig} from "../../../main";

@Injectable()
export class ServerService {

    // startSearchIp = AppConfig.startSearchIp;
    dataServerIp = AppConfig.dataServerIp;
    // httpServerIp = AppConfig.httpServerIp;
    ver = AppConfig.ver;

    // port = AppConfig.port;


    // dataServerIp = 'onebutton.co.il/movingaid/';
    // httpServerIp = 'http://onebutton.co.il/movingaid/';

    constructor(private http: HttpClient) {
    }

    // public getEndPoint() {
    //     return this.httpServerIp;
    // }

    public searchDevice(i, ip) {

        return new Promise((resolve, reject) => {
            this.http.get(ip, {})
                .subscribe(res => {
                    resolve({'i': i, res: res});
                })
        });
    }

    //An option to talk to other server. for payment e.g.
    public get(token, protocolName, filters, domain = '') {
        // console.log(domain)

        return new Promise((resolve, reject) => {
            var url = 'http://' + (domain != '' ? domain : this.dataServerIp) + "/" + protocolName + "/";
            console.log('go to server: ' + this.dataServerIp);
            console.log('go to url: ' + url);


            let httpOptions = {}
            if (domain == '') {
                httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': token ? "Token " + token : "",
                        'Content-Type': 'application/json;charset=UTF-8'
                    }),
                    params: filters
                };
            }

            var str = Object.keys(filters).map(function (key) {
                return key + '=' + filters[key];
            }).join('&');
            url += '?' + str

            //let options = new RequestOptions({ headers: myHeaders, params: myParams });
            //let params = new HttpParams().set("paramName",1).set("paramName2", 3); //Create new HttpParams

            this.http.get(url, httpOptions)
                .subscribe(res => {
                    console.log('request results from server:')
                    console.log(res);


                    //let data = res.json();
                    //console.log(res);
                    //console.log(res._body);
                    //console.log(res._body.token);

                    //resolve(res);
                    // resolve(res.json());
                    if (res)
                        resolve(res);
                    else
                        resolve({'data': {'models': [], 'count': 0}, 'offline': true});
                });

        });
    }

    public loadFile(token, url, filters) {
        return new Promise((resolve, reject) => {

            let httpOptions = {};
            httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': token ? "Token " + token : "",
                    'Content-Type': 'application/json;charset=UTF-8'
                }),
                params: filters
            };

            // let headers = new Headers();
            // headers.append('Authorization', token ? "Token " + token : "");
            // headers.append('Content-Type', 'application/json;charset=UTF-8');

            var fullurl = 'http://' + this.dataServerIp + "/" + url;

            this.http.get(fullurl, httpOptions).subscribe(data => {
                // console.log('back to heere!!');
                // console.log(data.text());
                // resolve(data.text()); //tmp remove tmpremove
            })
        });
    }

    public put(token, protocolName, data) {
        return new Promise((resolve, reject) => {
            var url = 'http://' + this.dataServerIp + "/" + protocolName;

            let httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': token ? "Token " + token : "",
                    'Content-Type': 'application/json;charset=UTF-8'
                })
            };

            this.http.put(url, JSON.stringify(data), httpOptions)
                .subscribe(res => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
        });

    }

    public post(token, protocolName, data, domain = '') {
        return new Promise((resolve, reject) => {
            var url = 'http://' + (domain ? domain : this.dataServerIp) + "/" + protocolName + '/';

            // let headers = new Headers();
            // headers.append('Authorization', token ? "Token " + token : "");
            // headers.append('Content-Type', 'application/json;charset=UTF-8');
            let httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': token ? "Token " + token : "",
                    'Content-Type': 'application/json;charset=UTF-8'
                })
            };

            this.http.post(url, JSON.stringify(data), httpOptions)
                .subscribe(res => {

                    //let data = res.json();
                    //console.log('subscribe 1')
                    //console.log(res);
                    //console.log(res._body);
                    //console.log(res._body.token);
                    //resolve(res);

                    if (res)
                        resolve(res);
                    else
                        reject(false);
                })

        });

    }

    public delete(token, protocolName, filters) {
        return new Promise((resolve, reject) => {
            var url = 'http://' + this.dataServerIp + "/" + protocolName + "/";

            let httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': token ? "Token " + token : "",
                    'Content-Type': 'application/json;charset=UTF-8'
                }),
                params: filters
            };

            this.http.delete(url, httpOptions)
                .subscribe(res => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
        });
    }

    public upload(token, protocolName, file, filters, data) {
        return new Promise((resolve, reject) => {
            var f = [];

            for (var key in filters) {
                f.push(key + '=' + filters[key]);
            }

            var params = {};
            for (var key in data) {
                if (data[key]) {
                    params[key] = data[key];
                }
            }


            var url = 'http://' + this.dataServerIp + "/" + protocolName + "/?" + f.join("&");

            //url: url,
            //data: params,
            //sendFieldsAs: 'form',

            let httpOptions = {};
            httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': token ? "Token " + token : "",
                    'Content-Type': 'application/octet-stream',
                    'Upload-Content-Type': 'png'
                }),
                params: filters
            };

            // let headers = new HttpHeaders();
            // headers.append('Authorization', token ? "Token " + token : "");
            // //headers.append('Content-Type', 'multipart/form-data');
            // headers.set('Content-Type', 'application/octet-stream');
            // headers.set('Upload-Content-Type', 'png')

            var blob = this.dataURItoBlob(file.src);
            var fd = new FormData(document.forms[0]);

            params['file'] = blob;
            // console.log(params)

            this.http.post(url, params, httpOptions)
                .subscribe(res => {
                    // resolve(res.json()); //tmpremove
                }, (err) => {
                    reject(err);
                });


        });
    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else {
            // unescape
            byteString = dataURI.split(',')[1];
        }

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    getHost() {
        return '10.100.102.8:82'
    }

    public getVersion() {
        return this.ver;
    }
}
