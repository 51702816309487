import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {
    AppComponent, AppTopsliderComponent, ShapsBlogComponent, ShapsContactComponent, ShapsFeatursComponent,
    ShapsNewappComponent, ShapsNewsletterComponent, ShapsPortfolioComponent, ShapsPreffesionalComponent,
    ShapsServicesComponent, ShapsTestimonialsComponent,
    ShapsTopsliderComponent,
} from './app.component';
import {ActivatedRoute, RouterModule, Routes} from "@angular/router";
import {ShapsComponent} from './shaps/shaps.component';
import {AppRoutingModule} from "./app-routing/app-routing.module";
import {HomeComponent} from './home/home.component';
import {ModelProvider} from "./framework/providers/modelProvider/ModelProvider";
import {ServerService} from "./framework/providers/server/server";
import {HttpClientModule} from "@angular/common/http";
import {ModelClasses} from "./model/modelClasses";


@NgModule({
    declarations: [
        AppComponent,
        ShapsComponent,
        HomeComponent,
        ShapsNewappComponent,
        ShapsTopsliderComponent,
        ShapsPreffesionalComponent,
        AppTopsliderComponent,
        ShapsPortfolioComponent,
        ShapsFeatursComponent,
        ShapsTestimonialsComponent,
        ShapsBlogComponent,
        ShapsNewsletterComponent,
        ShapsContactComponent,
        ShapsServicesComponent,
    ],
    imports: [
        ModelClasses,
        HttpClientModule,
        FormsModule,

        BrowserModule,
        AppRoutingModule,
        // RouterModule.forRoot([])
    ],
    providers: [ModelProvider, ServerService],

    bootstrap: [AppComponent]
})
export class AppModule {
}
