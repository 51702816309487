import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShapsComponent} from "../shaps/shaps.component";
import {HomeComponent} from "../home/home.component";

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'shaps',
        component: ShapsComponent,
    },
    {
        path: 'oneapp',
        component: ShapsComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule {
}