export class Contact {
    data = {};

    constructor(public mdata:any) {
        if (mdata) {
            this.data = mdata;
        }
    }

    title = "Contact";
    prefix = "contact";
    icon = "glyphicon glyphicon-book";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        companyName: {'title': 'CompanyName', 'value': null, 'type': 'text'},
        name: {'title': 'Name', 'value': null, 'type': 'text'},
        homePhone: {'title': 'HomePhone', 'value': null, 'type': 'text'},
        cellphone: {'title': 'CellPhone', 'value': null, 'type': 'text'},
        email: {'title': 'Email', 'value': null, 'type': 'text'},
        comments: {'title': 'Comments', 'value': null, 'type': 'textarea'},
        description: {'title': 'Description', 'value': null, 'type': 'textarea'},
        useragent: {'title': 'useragent', 'value': null, 'type': 'text'},
        userPref: {'title': 'userPref', 'value': null, 'type': 'text'},
        zip: {'title': 'Zip', 'value': null, 'type': 'text'},
        address: {'title': 'Address', 'value': null, 'type': 'text'},

    }
}

