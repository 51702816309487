import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app.module';

// platformBrowserDynamic().bootstrapModule(AppModule);

export const AppConfig = {

    bisPrefix: null,
    appctrl: null, // ??

    defaultLang: 'heb',
    langs: ['heb', 'en'],
    ltrLangs: ['heb'],

    // dataServerIp:'192.168.1.50:98',
    // httpServerIp:'http://192.168.1.50:98/',
    // ver:"3.10"

    startSearchIp: '10.0.0.',
    // appprefix:'sisappadmin',
    appprefix: '',
    // searchPort:null,
    searchPort: '88',
    port: '88',
    ver: "3.10",

    // dataServerIp: '10.100.102.12:83',
    dataServerIp: 'onebutton.co.il/zeshir',
    httpServerIp: 'onebutton.co.il/zeshir/',
    // httpServerIp: '10.100.102.12:83/',
    // httpServerIp: '192.168.1.2:98/',

    // dataServerIp:'10.100.102.13:98',
    // httpServerIp:'10.100.102.13:98/',

    logs: {'str': ''},

    isManager:false



};

// dataServerIp = '10.100.102.11:221';
// httpServerIp = 'http://10.100.102.11:221/';
//dataServerIp = 'onebutton.co.il/movingaid';
//httpServerIp = 'http://onebutton.co.il/movingaid/';
