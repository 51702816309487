import {Injectable} from '@angular/core';
import {ServerService} from "../server/server";
import {ModelClasses} from "../../../model/modelClasses";
import {AppConfig} from "../../../main";

@Injectable()
export class ModelInterface {
    prefix: string;
    data: Object = {};
    dataFields: Object = {};
    title: string;
    icon: string;
    showDefaultsCols: boolean;
    defaultHeaders: Array<string>;

    constructor(public data1: any) {
        this.data = data1;
    }
}

@Injectable()
export class ResInterface {
    count: number;
    results: Object;
    models: Array<any>;

    constructor() {
    }
}

@Injectable()
export class ModelProvider {
    constructor(private server: ServerService,
                private modelClasses: ModelClasses) {

        // this.storage = new Storage();
    }

    public create(model, filters = {}, domain = null) {
        return new Promise((resolve, reject) => {

            return this.postModel(model.prefix, model, filters, domain).then((res) => {
                resolve(res);
            });
        })
    }

    private postModel(url, model: ModelInterface, filters, domain = '') {
        //array to object!!
        var data: Object = {};
        for (var key in model.data) {
            if (model.data[key] && Array.isArray(model.data[key]))
                data[key] = model.data[key].join(',');
            else
                data[key] = model.data[key];
        }

        return new Promise((resolve, reject) => {
            this.post(url, data, filters, domain).then((res: Object) => {
                if (!res)
                    return;
                //update model with results

                //console.log('manyToMany???');

                for (var key in model.dataFields) {
                    if (model.dataFields[key]) {
                        model.data[key] = res[key];
                    }

                    if (model.dataFields[key].manyToMany) {
                        //console.log(model.data[key]);
                    }
                }

                resolve(model);
            });
        });

    }

    private post(url, data, filters, domain='') {
        if (AppConfig.bisPrefix) filters['pref'] = AppConfig.bisPrefix;
        filters['appctrl'] = AppConfig.appctrl;

        //filters
        var f = [];
        for (var key in filters) {
            f.push(key + '=' + filters[key]);
        }

        var urlwithparams = url + "/?" + f.join("&");

        return new Promise((resolve, reject) => {
            //this.userData.getUserdata().subscribe((userData: User) => {

            this.server.post("", urlwithparams, data, domain)
                .then((res) => {

                    resolve(res);
                });
        });
    }

}
